<template>
    <v-container class="grey lighten-5 pb-0 ">
      <v-form ref="form" v-model="valid" lazy-validation >

      <v-row class="mt-4" no-gutters>
        <v-col cols="4" class="px-4">
          <v-text-field :value="user.date_joined" 
                        :label="user.date_joined ? 'CREATED' : 'NO VALUE'" 
                        :hint="user.joinedAtAgo" 
                        persistent-hint 
                        readonly 
                        :disabled="user.date_joined ? false : true"></v-text-field>
        </v-col>
        <v-col cols="4" class="px-4">
          <v-text-field :value="user.last_login" 
                        :label="user.last_login ? 'LAST LOGIN' : 'NOT LOGED IN'" 
                        :hint="user.lastLoginAtAgo"
                        :disabled="user.last_login ? false : true"
                         persistent-hint 
                         readonly></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-15" no-gutters>
        <v-col cols="5" class="px-4">
          <v-text-field outlined shaped v-model="user.first_name" label="FIRSTNAME" :rules="[ v => !!v  || 'Required.']" hide-details="auto"></v-text-field>
        </v-col>
        <v-col cols="5" class="px-4">
          <v-text-field outlined shaped v-model="user.last_name" label="LASTNAME" :rules="[ v => !!v  || 'Required.']" hide-details="auto"></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-8"  no-gutters>
        <v-col  cols="5" class="px-4">
          <v-text-field outlined shaped v-model="user.email" label="EMAIL" :rules="[ v => !!v  || 'Required.']" hide-details="auto"></v-text-field>
        </v-col>
        <v-col cols="5" class="px-4">
          <v-text-field outlined shaped v-model="user.username" label="USERNAME" :rules="[ v => !!v  || 'Required.']" hide-details="auto"></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-4" no-gutters>
        <v-col cols="4" class="px-4">
          <v-switch v-model="user.is_staff" inset label="ADMINISTRATOR" ></v-switch>          
        </v-col>
        <v-col cols="3" class="px-4">
          <v-switch v-model="user.is_active" inset label="ACTIVE" ></v-switch>          
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8"></v-col>
        <v-col>
            <v-btn block color="primary" dark class="mb-2" @click="save" >
              <v-icon class="pr-2">mdi-account-check</v-icon>Save
            </v-btn>

        </v-col>
        <v-col cols="2"></v-col>

      </v-row>


      </v-form>
    </v-container>
</template>
<script>
import {getUser, updateUser} from '@/api/users'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
TimeAgo.addLocale(en)

const timeAgo = new TimeAgo('en-UK')

  export default {
    name: 'userInfo',
    data () {
      return {
        valid: true,
        userId: this.$route.params.id,
        user:{
          id: 2,
          is_active: true,
          last_login: null,
          date_joined: '',
          username: '',
          email: '',
          first_name: '',
          last_name: '',
          is_staff: true          
        }
      }
    },
    methods: {
      toStrDate(dt){
        if ( dt ) {
          var d = new Date(dt)
          return d.toDateString() + '    ' + d.toLocaleTimeString()
        }
        else{
          return ''
        }
      },
      agoFormat(dt){
        if ( dt ) {
          var d = new Date(dt)
          return  timeAgo.format(d)
        }
        else{
          return ''
        }
      },
      save(){
        var isValidated = this.$refs.form.validate()
        if (isValidated){
          const _this = this
          updateUser(this.userId, this.user, function(){}, function(err){
            if (err.status == 400){
              var msg = 'Invalid data entered! '
              if(err.data.email) {
                msg += 'Another user with ' + _this.selectedUser.email + ' already exist!'
              } else {
                for ( const k of Object.keys(err.data)){
                  msg += k + ':' + err.data[k][0]
                }
              }
              _this.$root.notify.show({message: msg, type:'error'})
            } else {
              _this.$root.notify.show({message: 'Internal Error!', type:'error'})
            }              
          }, 'Profile updated successfully')
        }
      }
    },
    created () {
      const _this = this
      getUser(this.userId, function(ds){
        ds.last_login = _this.toStrDate(ds.last_login)
        ds.date_joined = _this.toStrDate(ds.date_joined)
        ds.lastLoginAtAgo = _this.agoFormat(ds.createdAt)
        ds.joinedAtAgo = _this.agoFormat(ds.date_joined)
        _this.user = ds
      })
    }

  }
</script>
